.custom-card {
    background: white;
    border-radius: 24px;
    margin-top: 1rem;
}

.custom-card.bordered {
    border: solid 1px rgb(173, 173, 173);
}

.custom-card > .custom-card-header {
    font-size: 1.3em;
    font-weight: 400;
    text-align: center;
    color: white;
    background: rgb(0, 156, 26);
    border-radius: 24px 24px 0 0;
    padding: 0.5rem 0;
    height: 54px;
    line-height: 38px;
}

.custom-card .custom-card-header .btn {
    background: rgb(245, 245, 245);
    color: rgb(0, 119, 20);
    font-weight: 500;
    border: 2px solid rgb(0, 119, 20);
    padding-top: 5px;
    padding-bottom: 5px;
}

.custom-card .custom-card-header .btn:last-child {
    border-top-right-radius: 15px;
}

.custom-card .custom-card-header .btn:hover {
    background: rgb(206, 224, 209) !important;
}

.custom-card > .custom-card-body {
    padding: 1rem 1.5rem 1.5rem 1.5rem;
}
