.checkbox-item {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0 0.25rem 0.25rem 0;
    padding: 0.375rem 0.75rem;
    flex-grow: 1;
}

.checkbox-item-image {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem 0 0 0.25rem;
    padding: 0.375rem 0.75rem;
}

.checkbox-item-cute {
    background-color:lightpink;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0 0.25rem 0.25rem 0;
    padding: 0.375rem 0.75rem;
    flex-grow: 1;
}
