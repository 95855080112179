.progress.card-header-progress {
    height: 40px;
    border-radius: 24px;
}

.progress.card-header-progress .progress-bar {
    color: rgb(46, 46, 46);
    font-size: 17px;
    font-weight: 500;
    overflow: visible;
    background-color: rgb(73 205 95);
    text-align: left;
}
