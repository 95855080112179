header nav {
    border-bottom: solid 2px rgb(0, 156, 26);
    font-weight: 500;
}

header nav a {
    color: rgb(0, 156, 26) !important;
}

header nav a:hover {
    text-decoration: underline;
}

header nav .navbar-nav a {
    position: relative;
    padding-left: 12px !important;
    padding-right: 12px !important;
}

@media (min-width: 768px) {
    header nav .navbar-nav a:not(:last-child):after {
        position: absolute;
        content: '';
        right: -1px;
        width: 2px;
        height: 60%;
        top: 20%;
        background-color: rgb(0, 156, 26);
    }
}
