.yearly-calendar {
    text-align: center;
    overflow-x: auto;
    padding-bottom: 8px;
}

.yearly-calendar .calendar-container {
    display: inline-block;
    font-size: 0.7em;
}

.yearly-calendar .calendar-container .grid-container {
    display: inline-grid;
    gap: 4px;
    grid-template-columns: auto repeat(52, auto);
    grid-template-rows: repeat(7, auto);
    grid-auto-flow: column;
}

.month-label-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-left: 62px;
    padding-right: 44px;
}
