.day-picker {
    display: block;
    height: 40px;
}

.day-picker .day-picker-el {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: black;
    background: #ebebeb;
    border: solid 1px rgb(158, 158, 158);
    text-align: center;
    font-size: 15px;
    line-height: 40px;
    box-sizing: content-box;
    transition: 0.25s;
    user-select: none;
}

.day-picker .day-picker-el:not(:last-child) {
    margin-right: 12px;
}

.day-picker .day-picker-el.selected {
    background: #8ef78e;
    border: solid 1px rgb(0, 199, 0);
    transition: 0.35s;
}
